var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-table",
          {
            ref: "table",
            attrs: {
              title: "평가항목 목록",
              tableId: "table",
              columns: _vm.grid.columns,
              data: _vm.grid.data,
              usePaging: false,
              editable: _vm.revisionable,
              columnSetting: false,
              selection: "multiple",
              rowKey: "selfInspectionItemMstId",
            },
          },
          [
            _c("template", { slot: "table-button" }, [
              _c(
                "div",
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.revisionable
                        ? _c("c-btn", {
                            attrs: { label: "LBLADD", icon: "add" },
                            on: { btnClicked: _vm.addRow },
                          })
                        : _vm._e(),
                      _vm.editable && _vm.revisionable
                        ? _c("c-btn", {
                            attrs: { label: "LBLREMOVE", icon: "remove" },
                            on: { btnClicked: _vm.removeRow },
                          })
                        : _vm._e(),
                      _vm.editable && _vm.revisionable
                        ? _c("c-btn", {
                            attrs: { label: "LBLSAVE", icon: "save" },
                            on: { btnClicked: _vm.saveRow },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }