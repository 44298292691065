<template>
  <div>
    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailForm" title="평가 항목 개정이력" bgClass="grey-3">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn label="개정" icon="restart_alt" @btnClicked="openRevision" v-if="editable && revisionable" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              type="none"
              :comboItems="revisionItems"
              itemText="codeName"
              itemValue="code"
              label="제/개정 번호"
              name="sysRevision"
              @input="getList"
              v-model="searchParam.sysRevision">
            </c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-text
              :disabled="true"
              label="제/개정 일자"
              :value="data.revisionDt"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-text
              :disabled="true"
              label="제/개정 사유"
              :value="data.revisionContent"
            />
          </div>
        </template>
      </c-card>
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="평가항목 목록"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :usePaging="false"
        :editable="revisionable"
        :columnSetting="false"
        selection="multiple"
        rowKey="selfInspectionItemMstId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <c-btn label="LBLADD" v-if="editable && revisionable" icon="add" @btnClicked="addRow" />
              <c-btn label="LBLREMOVE" v-if="editable && revisionable" icon="remove" @btnClicked="removeRow" />
              <c-btn label="LBLSAVE" v-if="editable && revisionable" icon="save" @btnClicked="saveRow" />
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
    <!-- <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">자체감사 평가항목 개정</div>
        </q-card-section>

        <q-card-section class="q-pt-none" style="padding-top:20px">
          <c-text
            :required="true"
            label="개정"
            :editable="false"
            name="revisionNum"
            v-model="revisionInfo.revisionNum">
          </c-text>
          <c-datepicker
            :required="true"
            label="개정일자"
            name="revisionDt"
            v-model="revisionInfo.revisionDt"
          />
          <c-text
            label="제/개정사유"
            name="revisionContent"
            v-model="revisionInfo.revisionContent">
          </c-text>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelRevision" />
          <q-btn flat label="개정" @click="saveRevision" />
        </q-card-actions>
      </q-card>
    </q-dialog> -->
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "audit-table",
  data() {
    return {
      revisionItems: [],
      data: {
        sysRevision: null,
        revisionNum: '',
        revisionContent: '',
        revisionDt: '',
      },
      prompt: false,
      revisionInfo: {
        sysRevision: '',
        revisionNum: '',
        revisionDt: '',
        revisionContent: '',
        regUserId: '',
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'LBLSORTORDER',
            align: 'left',
            type: 'number',
            style: 'width:70px',
            sortable: false
          },
          {
            required: true,
            name: 'itemMstName',
            field: 'itemMstName',
            label: 'LBLITEMS',
            align: 'left',
            type: 'text',
            sortable: false
          },
          {
            required: true,
            name: 'maxRealScoring',
            field: 'maxRealScoring',
            label: '최고실배점',
            align: 'center',
            type: 'number',
            style: 'width:200px',
            sortable: false
          },
          {
            required: true,
            name: 'changeCalculation',
            field: 'changeCalculation',
            label: '환산계수',
            align: 'center',
            type: 'number',
            style: 'width:200px',
            sortable: false
          },
          {
            required: true,
            name: 'maxChangeScoring',
            field: 'maxChangeScoring',
            label: '최고환산점수',
            align: 'center',
            type: 'number',
            style: 'width:200px',
            sortable: false
          },
        ],
        data: [],
      },
      searchParam: {
        sysRevision: null,
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      revisionUrl: '',
      lastRevision: null,
      revisionable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.revisionUrl = selectConfig.sop.pca.item.revs.item;
      this.listUrl = selectConfig.sop.pca.item.revs.url;
      this.saveUrl = transactionConfig.sop.pca.item.save.url;
      this.deleteUrl = transactionConfig.sop.pca.item.delete.url;
      this.getRevision();
    },
    getRevision() {
      this.$http.url = this.revisionUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.revisionItems = _result.data;
        this.searchParam.sysRevision = this.revisionItems[0].code;
        this.lastRevision = this.revisionItems[0].code;
        this.getList();
      },);
    },
    getList() {
      if (this.lastRevision == this.searchParam.sysRevision) {
        this.revisionable = true;
      } else {
        this.revisionable = false;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        if (_result.data.length > 0) {
          this.data = _result.data[0];
        }
      },);
    },
    addRow() {
      this.grid.data.push({
        editFlag: 'C',
        selfInspectionItemMstId: uid(),  // 자체감사 평가항목 일련번호
        itemMstName: '',  // 자체감사 평가항목명
        maxRealScoring: '',  // 최고실배점
        changeCalculation: '',  // 환산계수
        maxChangeScoring: '',  // 최고환산점수
        sortOrder: this.grid.data.length + 1,  // 순번
        sysRevision: this.data.sysRevision,  // 시스템 개정번호
        revisionNum: this.data.revisionNum,  // 개정번호
        revisionContent: this.data.revisionContent,  // 개정사유
        revisionDt: this.data.revisionDt,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        delFlag: 'N',  // 삭제여부
      });
    },
    saveRow() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = this.grid.data;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getList();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } 
    },
    removeRow() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    // openRevision() {
    //   this.prompt = true;
    //   this.$http.url = selectConfig.com.revision.getversion;
    //   this.$http.type = 'GET';
    //   if (this.revisionItems.length > 0) {
    //     let rInfo = this.revisionItems[0];
    //     this.$http.param = {revisionNum: rInfo.codeName}
    //     this.$http.request((_result) => {
    //       this.revisionInfo = {
    //         sysRevision: parseInt(rInfo.code) + 1,
    //         revisionNum: _result.data,
    //         revisionDt: this.$comm.getToday(),
    //         revisionContent: '',
    //         regUserId: this.$store.getters.user.userId,
    //       }
    //     });
    //   } else {
    //     this.$http.param = {revisionNum: ''}
    //     this.$http.request((_result) => {
    //       this.revisionInfo = {
    //         sysRevision: '1',
    //         revisionNum: _result.data,
    //         revisionDt: this.$comm.getToday(),
    //         revisionContent: '',
    //         regUserId: this.$store.getters.user.userId,
    //       }
    //     });
    //   }
    // },
    // cancelRevision() {
    //   this.prompt = false;
    //   this.revisionInfo = {
    //     sysRevision: '',
    //     revisionNum: '',
    //     revisionDt: '',
    //     revisionContent: '',
    //   }
    // },
    // saveRevision() {
    //   if(this.revisionInfo.sysRevision != '') {
    //     window.getApp.$emit('CONFIRM', {
    //       title: 'LBLCONFIRM',
    //       message: '현재 자체감사 평가항목을 개정하시겠습니까?',
    //       type: 'info',
    //       // 확인 callback 함수
    //       confirmCallback: () => {
    //         this.$http.url = transactionConfig.sop.pca.item.save.url + '/rev';
    //         this.$http.type = 'POST';
    //         this.$http.param = this.revisionInfo;
    //         this.$http.request(() => {
    //           window.getApp.$emit('ALERT', {
    //             title: 'LBLGUIDE', // 안내
    //             message: '개정번호가 추가되었습니다.', 
    //             type: 'success', // success / info / warning / error
    //           });
    //           this.getRevision();
    //           this.prompt = false;
    //         });
    //       },
    //       // 취소 callback 함수
    //       cancelCallback: () => {
    //       },
    //     });
    //   }
    // },
  },
};
</script>
